import React from 'react'
import MyAddressesContainer from '../components/MyAccount/MyAddresses'
import { PgPageProps } from '../types/PgPages'
import withPageTransition from '../components/withPageTransition'

const MyAddressesPage = ({ pageContext: { currentCity, productCategories } }: PgPageProps) => (
  <MyAddressesContainer currentCity={currentCity} categories={productCategories} />
)

export default withPageTransition(MyAddressesPage)
