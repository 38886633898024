import React, { useEffect, useState, useRef } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import loadable from '@loadable/component'
import {
  fetchAddresses,
  useAuth,
  AddressCard,
  breakpoints,
  useResolution,
  FormattedAddress,
  deleteAddress,
  updateFavoriteAddress,
  useLocation,
  useShoppingCart,
  InfoMessage,
  getAuth,
  cityChangeMessage,
} from '@ecommerce/shared'
import { City } from '@ecommerce/shared/src/services/Location/cities'
import Layout from '../../Layout'
import AccountLayout from '../../../templates/UserMenu'
import { updateFavorites } from '../../Checkout/utils'
import { getNavBar } from '../CustomNavBar'
import { FlatLocationProductCategory } from '../../../types/PgPages'

const SkeletonCards = loadable(() => import('./SkeletonCards'))
const NoAddress = loadable(() => import('../../Checkout/components/NoAddress'))
const ConfirmationAlert = loadable(() => import('../../ConfirmationAlert'))

type Props = {
  currentCity: City
  categories?: FlatLocationProductCategory[]
}

const Wrapper = styled.div<{ isLoading: boolean }>`
  width: 100%;
  margin: auto;
  opacity: ${({ isLoading }) => (isLoading ? '0.5' : '1')};
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
  transition: opacity 0.35s;

  .MyAddresses {
    height: 100%;
  }

  .no-addresses-notice {
    margin: -45px auto 0;

    img {
      margin: 15px auto;
      width: 80px;
    }
  }

  @media screen and (${breakpoints.tabletLandscape.max}) {
    max-width: 416px;
  }
`

const AddressCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;

  .address-card {
    width: 48%;
    height: auto;
    align-self: stretch;
  }

  @media screen and (${breakpoints.tabletLandscape.max}) {
    .address-card {
      width: 100%;
    }
  }
`

const AddNewAddress = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 15px;

  button {
    color: ${({ theme }) => theme.colors.primary};
    background: none;
    border: none;
    padding: 0;
    font-size: inherit;
    cursor: pointer;
  }

  @media screen and (${breakpoints.tabletLandscape.max}) {
    text-align: center;
  }
`

const ErrorWrapper = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-weight: bold;

  svg {
    fill: ${({ theme }) => theme.colors.error};
  }
`

const MyAddressesContainer = ({ currentCity, categories }: Props) => {
  const {
    state: { firstName, lastName },
  } = useAuth()
  const isAuth = getAuth()
  const { isDesktop } = useResolution()
  const { empty: emptyCart } = useShoppingCart()
  const {
    state: { byHashCities },
  } = useLocation()
  const cities = Object.values(byHashCities)

  const [error, setError] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isChangingCity, setIsChangingCity] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [hasAddressAvailable, setHasAddressAvailable] = useState(true)
  const [addresses, setAddresses] = useState<FormattedAddress[]>([])

  const deletingAddress = useRef<{ id: string; parent: string }>({ id: '', parent: '' })
  const defaultAddressID = useRef<string>()

  useEffect(() => {
    // Redirect unauthenticated users
    if (!isAuth) navigate(`/${currentCity.slug}`)

    // Get  user addresses
    const getAddresses = async () => {
      try {
        const [items, hasAvailable] = await fetchAddresses(currentCity.id, 'addresses')

        if (!hasAvailable) {
          setHasAddressAvailable(hasAvailable)
        } else {
          setIsLoading(false)
        }

        setAddresses(items)
      } catch {
        setError(true)
      }
    }

    if (isAuth) getAddresses()
  }, [])

  const navigateToForm = async (
    action: 'new-address' | 'edit-address',
    edittingAddress?: FormattedAddress | null,
    useCurrentCity?: boolean,
  ) => {
    await navigate(`/${currentCity.slug}/direccion`, {
      state: {
        action,
        addresses,
        useCurrentCity,
        edittingAddress,
        isDefault: addresses.length === 0,
      },
    })
  }

  const handleDelete = (id: string, parentId?: string) => {
    if (parentId) {
      deletingAddress.current = {
        id,
        parent: parentId,
      }

      setIsDeleting(true)
    }
  }

  const OnDeleteAddress = async () => {
    try {
      // Lock page
      setIsDeleting(false)
      setIsPageLoading(true)

      await deleteAddress({ ...deletingAddress.current })

      const updatedAddreses = addresses.filter(({ id }) => id !== deletingAddress.current.id)

      setAddresses(updatedAddreses)
    } catch {
      setError(true)
    } finally {
      setIsPageLoading(false)
    }
  }

  const handleFavorite = (isChecked: boolean, id: string, key: number) => {
    const newFavorites = updateFavorites(addresses, isChecked, id)
    const addressCity = addresses[key].cityId

    if (addressCity !== currentCity.id) {
      defaultAddressID.current = id

      setIsChangingCity(true)
    } else {
      updateFavoriteAddress(newFavorites)

      setAddresses(newFavorites)
    }
  }

  const onCityChange = async () => {
    try {
      // Lock page
      setIsChangingCity(false)
      setIsPageLoading(true)

      const newDefaultId = defaultAddressID.current
      const newDefault = addresses.filter(({ id }) => id === newDefaultId)[0]
      const newCity = cities.filter(({ id }) => id === newDefault.cityId)[0]
      const newFavorites = updateFavorites(addresses, true, defaultAddressID.current)

      await updateFavoriteAddress(newFavorites)

      // Clear cart
      emptyCart()

      await navigate(`/${newCity.slug}/mis-direcciones`)
    } catch {
      setError(true)
    } finally {
      setIsPageLoading(false)
    }
  }

  const NavBar = () => getNavBar('../', () => navigate('/'))

  return (
    <Layout
      currentCity={currentCity}
      title="Embonor Ecommerce - Mis Direcciones"
      navbar={isDesktop ? undefined : NavBar}
      categories={categories}
    >
      <Wrapper isLoading={isPageLoading}>
        {isDeleting ? (
          <ConfirmationAlert
            text="¿Estás seguro que quieres eliminar esta dirección?"
            confirmButtonText="Eliminar"
            onConfirm={OnDeleteAddress}
            onClose={() => setIsDeleting(false)}
          />
        ) : null}
        {isChangingCity ? (
          <ConfirmationAlert
            text={cityChangeMessage}
            confirmButtonText="Mantener ciudad"
            cancelButtonText="Cambiar"
            onConfirm={() => setIsChangingCity(false)}
            onClose={() => setIsChangingCity(false)}
            onCancel={onCityChange}
          />
        ) : null}
        {!hasAddressAvailable ? (
          <ConfirmationAlert
            text="No tienes ninguna dirección asociada a esta ciudad."
            confirmButtonText="Agregar dirección"
            onConfirm={() => navigateToForm('new-address', null, true)}
            onClose={() => {
              setHasAddressAvailable(true)
              setIsLoading(false)
            }}
          />
        ) : null}
        <AccountLayout
          titleClassName="MyAddresses__title"
          contentClassName="MyAddresses"
          currentCity={currentCity}
          customerName={`${firstName} ${lastName}`}
          title="Mis Direcciones"
          activeMenuIndex={2}
        >
          <ErrorWrapper>
            <InfoMessage
              className="error-message"
              isHidden={!error}
              message="Error en la conexión, intenta de nuevo por favor."
            />
          </ErrorWrapper>
          <AddressCards>
            {isLoading ? (
              <SkeletonCards />
            ) : (
              <>
                {addresses.length !== 0 ? (
                  addresses.map((address, key) => (
                    <AddressCard
                      isAddressesPage
                      className="address-card"
                      key={address.id}
                      title={address.shipping_name}
                      isChecked={address.isFavorite}
                      isDefault={address.isFavorite}
                      isDesktop={isDesktop}
                      address={{
                        name: address.name,
                        address: address.shipping_address,
                        city: address.cityName,
                      }}
                      onEdit={() => navigateToForm('edit-address', address)}
                      onCheck={(isChecked) => handleFavorite(isChecked, address.id, key)}
                      onDelete={() => handleDelete(address.id, address.customer_address)}
                    />
                  ))
                ) : (
                  <NoAddress
                    text="Aquí estarán tus direcciones de despacho"
                    onClick={() => navigateToForm('new-address', null, false)}
                    className="no-addresses-notice"
                  />
                )}
              </>
            )}
          </AddressCards>
          {addresses.length !== 0 ? (
            <AddNewAddress>
              <button onClick={() => navigateToForm('new-address')} type="button">
                Agregar&nbsp;
              </button>
              nueva dirección de despacho
            </AddNewAddress>
          ) : null}
        </AccountLayout>
      </Wrapper>
    </Layout>
  )
}

export default MyAddressesContainer
